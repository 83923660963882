import { useMutation, useQueryClient } from 'react-query';
import { FavoriteCategory } from '../../sci-ui-components/types/favoriteCategory';
import {
  createFavoritePlayersCategory,
  CreateFavoritePlayersCategoryParams,
  deleteFavoriteCategory,
  DeleteFavoriteCategoryParams,
  updateFavoriteCategory,
  UpdateFavoriteCategoryParams,
} from '../../services/sciApi/favorites';
import useAnalytics from '../analytics/useAnalytics';
import { showError, showSuccess } from '../../services/toaster';
import { playersCollectibleType } from '../players/constants';
import { keyPrefix } from './useFavoritePlayersCategories';
import { waitForConfirmation } from 'sci-ui-components/ConfirmationDialog';
import { SciApiError } from '@/services/sciApi/types';

const collectibleType = playersCollectibleType;

export default function useFavoritePlayersCategoriesMutations() {
  const queryClient = useQueryClient();
  const { trackEvent } = useAnalytics();
  const create = useMutation({
    mutationFn: (params: CreateFavoritePlayersCategoryParams) => createFavoritePlayersCategory(params),
    mutationKey: ['add-favorite-players-category'],
    onSuccess: (newCategory) => {
      queryClient.setQueryData(
        [keyPrefix],
        (categories: FavoriteCategory[] | undefined) => categories?.concat(newCategory) ?? [newCategory]
      );
      queryClient.refetchQueries([keyPrefix]);
      showSuccess({
        description: 'Successfully created new Category',
      });
      trackEvent({
        eventName: 'FAVORITES_PLAYERS_CATEGORY_ADDED',
        collectibleType,
      });
    },
    onError: (error: SciApiError) => {
      const errorMessage = error.details?.error;
      showError({
        description: errorMessage,
      });
    },
  });
  const update = useMutation({
    mutationFn: (params: UpdateFavoriteCategoryParams) => updateFavoriteCategory(params),
    mutationKey: ['update-favorite-players-category'],
    onSuccess: (_, params) => {
      queryClient.refetchQueries([keyPrefix]);
      showSuccess({
        description: `Successfully renamed favorites category to "${params.name}"`,
      });
      trackEvent({
        eventName: 'FAVORITES_PLAYERS_CATEGORY_UPDATED',
        collectibleType,
      });
    },
    onError: () => {
      showError({
        description: 'Failed to rename favorites category. Please try again.',
      });
    },
  });

  const remove = useMutation({
    mutationFn: (params: DeleteFavoriteCategoryParams) => deleteFavoriteCategory(params),
    mutationKey: ['delete-favorite-players-category'],
    onSuccess: () => {
      queryClient.refetchQueries([keyPrefix]);
      showSuccess({
        description: 'Successfully deleted favorites category',
      });
      trackEvent({
        eventName: 'FAVORITES_PLAYERS_CATEGORY_DELETED',
        collectibleType,
      });
    },
    onError: () => {
      showError({
        description: 'Failed to delete favorites category. Please try again.',
      });
    },
  });
  const removeWithConfirmation = async (params: DeleteFavoriteCategoryParams) => {
    const confirmed = await waitForConfirmation({
      title: 'Delete favorites category',
      description: 'Are you sure you want to delete this favorites category?',
    });
    if (confirmed) {
      await remove.mutateAsync(params);
    } else {
      trackEvent({
        eventName: 'FAVORITES_PLAYERS_CATEGORY_DELETE_CANCELLED',
        collectibleType,
      });
    }
    return confirmed;
  };

  return {
    create,
    update,
    removeWithConfirmation,
  };
}
