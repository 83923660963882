import type {
  Collectible,
  CollectiblesSet,
  Sport,
  CollectiblesVariation,
  CollectibleGrade,
  Player,
  CollectiblesSetVariationSportsCard,
  BoxType,
} from '@sportscardinvestor/schemas';
import type {
  NonCustomCollectible as OldNonCustomCollectible,
  NonCustomSportsCardCollectible as OldNonCustomSportsCardCollectible,
  NonCustomSealedWaxCollectible as OldNonCustomSealedWaxCollectible,
} from '../../../sci-ui-components/types/collectible';
import { CardSet } from '../../../sci-ui-components/types/cardSet';
import { Sport as OldSport } from '../../../sci-ui-components/types/sport';
import { CardVariation as OldCardVariation } from '../../../sci-ui-components/types/cardVariation';
import { CardSetVariation as OldCardSetVariation } from '../../../sci-ui-components/types/cardSetVariation';
import { CardGrade as OldCardGrade } from '../../../sci-ui-components/types/cardGrade';
import { SealedWaxBoxType as OldSealedWaxBoxType } from '../../../sci-ui-components/types/sealedWaxBoxType';
import { Player as OldPlayer } from '../../../sci-ui-components/types/player';

export function temp_convertCollectibleToOldNonCustomCollectible(item: Collectible): OldNonCustomCollectible {
  if (item.collectibleType === 'sports-card') {
    const sportsCard: OldNonCustomSportsCardCollectible = {
      ...item,
      id: Number(item.id),
      historicalStats: convertStats(item.stats, item.id),
      lastLiveSyncedAt: null,
      lastSyncedAt: null,
      adminImageUrl: item.imageUrl ?? null,
      imageUrl: item.gradelessImageUrl ?? null,
      isCustom: false,
      createdById: null,
      updatedById: null,
      queryId: null,
      query: item.query ?? null,
      createdAt: '',
      cardSet: temp_convertSeToOldSet(item.set),
      cardSetId: Number(item.set.id),
      gradeId: Number(item.grade.id),
      photo: item.imageUrl
        ? {
            cardNumber: '',
            imageUrl: item.imageUrl,
            id: 0,
            playerId: 0,
            setId: 0,
            specificQualifier: '',
            variationId: 0,
          }
        : null,
      playerId: Number(item.player.id),
      populationCount: item.stats.currentPopulationCount ?? null,
      populationLastUpdated: item.stats.populationCountUpdatedAt,
      setVariationId: Number(item.setVariation.id),
      variation: convertVariation(item.setVariation.variation),
      variationId: Number(item.setVariation.variation.id),
      cardNumber: item.cardNumber ?? null,
      collectibleType: 'sports-card',
      grade: convertGrade(item.grade),
      isRookie: item.isRookie,
      player: temp_convertPlayerToOldPlayer(item.player),
      serialNumber: item.serialNumber ?? null,
      setVariation: convertSetVariation(item.setVariation),
      specificQualifier: item.specificQualifier ?? null,
      updatedAt: '',
    };
    return sportsCard;
  }
  if (item.collectibleType === 'sealed-wax-card') {
    const sportsCard: OldNonCustomSealedWaxCollectible = {
      ...item,
      id: Number(item.id),
      historicalStats: convertStats(item.stats, item.id),
      lastLiveSyncedAt: null,
      lastSyncedAt: null,
      adminImageUrl: item.imageUrl ?? null,
      imageUrl: item.imageUrl ?? null,
      isCustom: false,
      createdById: null,
      updatedById: null,
      queryId: null,
      query: item.query ?? null,
      createdAt: '',
      cardSet: temp_convertSeToOldSet(item.set),
      cardSetId: Number(item.set.id),
      collectibleType: 'sealed-wax-card',
      updatedAt: '',
      boxTypeId: Number(item.boxType.id),
      boxType: convertBoxType(item.boxType),
    };
    return sportsCard;
  }
  throw new Error('Unexpected collectible type');
}

export function temp_convertSeToOldSet(item: CollectiblesSet): CardSet {
  if (item.collectibleType === 'sealed-wax-card') {
    return {
      displayText: [item.year, item.name, item.sport.name].filter((v) => !!v).join(' '),
      id: Number(item.id),
      activeFrequencyTier: 0,
      frequencyTier: 0,
      jobMasterSetId: null,
      lastLiveSyncedAt: null,
      lastSyncedAt: null,
      name: item.name,
      query: null,
      sport: convertSport(item.sport),
      sportId: Number(item.sport.id),
      waxQuery: item.query,
      year: item.year ?? '',
    };
  }
  return {
    displayText: [item.year, item.name, item.sport.name].filter((v) => !!v).join(' '),
    id: Number(item.id),
    activeFrequencyTier: 0,
    frequencyTier: 0,
    jobMasterSetId: null,
    lastLiveSyncedAt: null,
    lastSyncedAt: null,
    name: item.name,
    query: item.query,
    sport: convertSport(item.sport),
    sportId: Number(item.sport.id),
    waxQuery: null,
    year: item.year ?? '',
  };
}

function convertSport(item: Sport): OldSport {
  return {
    id: Number(item.id),
    name: item.name,
    query: item.query,
  };
}

function convertVariation(item: CollectiblesVariation): OldCardVariation {
  return {
    id: Number(item.id),
    name: item.name,
    query: item.query,
    deprecated: false,
  };
}

function convertSetVariation(item: CollectiblesSetVariationSportsCard): OldCardSetVariation {
  return {
    id: Number(item.id),
    query: item.query,
    packOdds: item.packOdds,
    printRun: item.printRun,
    setId: Number(item.set.id),
    variationId: Number(item.variation.id),
    variationType: item.variationType,
  };
}

function convertGrade(item: CollectibleGrade): OldCardGrade {
  return {
    id: Number(item.id),
    name: item.name,
    query: item.query,
    abbreviation: item.abbreviatedName,
    order: item.order ?? 0,
  };
}

function convertBoxType(item: BoxType): OldSealedWaxBoxType {
  return {
    id: Number(item.id),
    name: item.name,
    query: item.query,
  };
}

export function temp_convertPlayerToOldPlayer(item: Player): OldPlayer {
  return {
    id: Number(item.id),
    name: item.name,
    query: item.query,
    alias: item.aliases?.join(' ') ?? null,
    rookieYears: [],
    sport: convertSport(item.sport),
    sportId: Number(item.sport.id),
  };
}

function convertStats(
  item: Collectible['stats'],
  collectibleId: string
): Exclude<OldNonCustomCollectible['historicalStats'], null> {
  return {
    ...item,
    collectibleId: Number(collectibleId),
    firstSale: item.firstSaleDate,
    lastSale: item.lastSaleDate,
    createdAt: '',
    all: convertStat(item.last365, item.lastSaleDate),
    last365: convertStat(item.last365, item.lastSaleDate),
    last180: convertStat(item.last180, item.lastSaleDate),
    last90: convertStat(item.last90, item.lastSaleDate),
    last60: convertStat(item.last60, item.lastSaleDate),
    last30: convertStat(item.last30, item.lastSaleDate),
    last14: convertStat(item.last14, item.lastSaleDate),
    last7: convertStat(item.last7, item.lastSaleDate),
    updatedAt: '',
  };
}

function convertStat(
  item: Collectible['stats']['last365'],
  lastSale: string | null
): Exclude<OldNonCustomCollectible['historicalStats'], null>['last365'] {
  return {
    lastSale,
    avg: item.avgPrice ?? 0,
    change: item.priceChangeAmount ?? 0,
    changePercentage: item.priceChangePercentage ?? 0,
    count: item.totalSalesCount ?? 0,
    endAvg: item.endAvgPrice ?? 0,
    max: item.maxPrice ?? 0,
    min: item.minPrice ?? 0,
    startAvg: item.startAvgPrice ?? 0,
    total: item.totalSalesAmount ?? 0,
  };
}
