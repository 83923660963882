import { useCallback, useState } from 'react';
import useAnalytics from '../features/analytics/useAnalytics';
import { defaultPaginationLimitOptions } from '../constants';

interface PaginationState {
  limit: number;
  offset: number;
}

export interface PaginationStateConfig {
  limitOptions?: number[];
  defaultLimit?: number;
  onPageChange?: () => void;
  onLimitChange?: () => void;
}

export default function usePaginationState({
  limitOptions = defaultPaginationLimitOptions,
  defaultLimit = limitOptions[0],
  onLimitChange,
  onPageChange,
}: PaginationStateConfig = {}) {
  const { trackEvent } = useAnalytics();
  const [{ limit, offset }, setState] = useState<PaginationState>({
    limit: defaultLimit,
    offset: 0,
  });
  const page = Math.floor(offset / limit);

  const setLimit = useCallback(
    (limit: number) => {
      setState((s) => ({ ...s, limit, offset: 0 }));
      onLimitChange?.();
    },
    [setState, onLimitChange]
  );
  const setOffset = useCallback(
    (offset: number) => {
      setState((s) => ({ ...s, offset }));
      onPageChange?.();
      trackEvent({
        eventName: 'PAGINATION_OFFSET_CHANGED',
        offset,
      });
    },
    [setState, onPageChange, trackEvent]
  );
  const setPage = useCallback((page: number) => setOffset(page * limit), [setOffset, limit]);

  return {
    page,
    offset,
    limit,
    limitOptions,
    setLimit,
    setOffset,
    setPage,
  };
}
