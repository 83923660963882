import { memo, useEffect, useMemo, useState } from 'react';
import WidgetHeader from '../../../../sci-ui-components/widgets/WidgetHeader/WidgetHeader';
import WidgetFiltersRow from '../../../../sci-ui-components/widgets/WidgetFiltersRow/WidgetFiltersRow';
import TextSearchField from '../../../../sci-ui-components/forms/TextSearchField/TextSearchField';
import useDebouncedValue from '../../../../sci-ui-components/hooks/useDebouncedValue';
import Pagination from '../../../../sci-ui-components/Pagination/Pagination';
import { CollectibleType } from '../../../../sci-ui-components/types/collectibleType';
import SavedSearchesList from '../../../../sci-ui-components/collectibles/SavedSearchesList/SavedSearchesList';
import { collectibleNames } from '../../../../sci-ui-components/collectibles/constants';
import usePaginationState, { PaginationStateConfig } from '../../../../hooks/usePaginationState';
import useSortState from '../../../../hooks/useSortState';
import useFilteredData from '../../../../hooks/useFilteredData';
import useAnalytics from '../../../analytics/useAnalytics';
import SearchCategoryFilter from '../SearchCategoryFilter';
import useSavedSearches from '../useSavedSearches';
import useSelectedSearchCategory from '../useSelectedSearchCategory';
import useSavedSearchCategories from '../useSavedSearchCategories';
import useSavedSearchesMutations from '../useSavedSearchesMutations';
import { editSavedSearch } from '../EditSavedSearchDialog/index';
import SavedSearchesSortSelect from './SavedSearchesSortSelect';
import { SortOption } from './types';

import classes from './SavedSearchesListWidget.module.scss';
import { SavedSearch } from 'sci-ui-components/types/search';
import { SortDirection } from 'sci-ui-components/types/sort';

const paginationStateConfig: PaginationStateConfig = {
  defaultLimit: 10,
  limitOptions: [10],
};

export default memo(function SavedSearchesListWidget({
  className,
  collectibleType,
}: {
  className?: string;
  collectibleType: CollectibleType;
}) {
  const [selectedCategoryId, setSelectedCategoryId] = useSelectedSearchCategory({ collectibleType });
  const { page, limit, offset, setPage } = usePaginationState(paginationStateConfig);
  const [searchText, setSearchText] = useState<string | null>(null);
  const [debouncedSearchText] = useDebouncedValue(searchText);
  const { setSort, sortBy, sortDirection } = useSortState<SortOption>({
    defaultSortBy: 'createdAt',
    defaultSortDirection: 'desc',
  });
  const { data: favoritePlayersData, isLoading } = useSavedSearches(
    {
      categoryId: selectedCategoryId,
      collectibleType,
    },
    {
      keepPreviousData: true,
    }
  );
  const filteredSearches = useFilteredData(favoritePlayersData, 'title', searchText);
  const sortedSearches = useMemo(
    () => sortSavedSearches(filteredSearches ?? [], sortBy!, sortDirection),
    [filteredSearches, sortBy, sortDirection]
  );
  const searchesPageItems = useMemo(
    () => sortedSearches.slice(offset, offset + limit),
    [sortedSearches, limit, offset]
  );

  const { data: categories } = useSavedSearchCategories({ collectibleType });
  const { update, removeWithConfirmation } = useSavedSearchesMutations({ collectibleType });

  const { trackEvent } = useAnalytics();
  useEffect(() => {
    if (debouncedSearchText) {
      trackEvent({
        eventName: 'SEARCH_SAVED_SEARCH_APPLIED',
        searchText: debouncedSearchText,
        collectibleType,
      });
    }
  }, [trackEvent, debouncedSearchText, collectibleType]);

  return (
    <div className={className}>
      <WidgetHeader className={classes.header}>
        Saved {collectibleNames[collectibleType].singular} Searches
      </WidgetHeader>
      <WidgetFiltersRow dense>
        <SearchCategoryFilter
          selectedCategoryId={selectedCategoryId}
          onChange={setSelectedCategoryId}
          collectibleType={collectibleType}
          id={`saved-search-${collectibleType}-category`}
        />
        <SavedSearchesSortSelect
          onChange={setSort}
          sortBy={sortBy}
          sortDirection={sortDirection}
          collectibleType={collectibleType}
        />
        <TextSearchField value={searchText} onChange={setSearchText} placeholder="Search" className={classes.search} />
      </WidgetFiltersRow>
      <SavedSearchesList
        className={classes.list}
        items={searchesPageItems}
        isLoading={isLoading}
        emptyMessage="No saved searches found"
        categories={categories ?? []}
        onMove={({ newCategoryId, savedSearchId }) => {
          if (newCategoryId) {
            update.mutate({
              categoryId: newCategoryId,
              id: savedSearchId,
            });
          } else {
            editSavedSearch({
              collectibleType,
              savedSearchId: savedSearchId,
              mode: 'change-category',
            });
          }
        }}
        onRemove={(savedSearchId) =>
          removeWithConfirmation({
            id: savedSearchId,
          })
        }
        onEdit={(savedSearch) => {
          editSavedSearch({
            collectibleType,
            savedSearchId: savedSearch.id,
            categoryId: savedSearch.category?.id,
            title: savedSearch.title,
            mode: 'all',
          });
        }}
      />
      <Pagination
        limit={limit}
        onPageChange={setPage}
        page={page}
        noCurrentPageInfo
        totalItems={filteredSearches?.length}
      />
    </div>
  );
});

function sortSavedSearches(items: SavedSearch[], sortBy: SortOption, sortDirection: SortDirection): SavedSearch[] {
  const sorter = createSorter(sortBy, sortDirection);
  return [...items].sort(sorter);
}

function createSorter(sortBy: SortOption, sortDirection: SortDirection) {
  const directionModifier = sortDirection === 'asc' ? 1 : -1;
  return function sorter(item1: SavedSearch, item2: SavedSearch) {
    if (item1[sortBy] > item2[sortBy]) return directionModifier;
    else if (item1[sortBy] < item2[sortBy]) return -directionModifier;
    else return 0;
  };
}
