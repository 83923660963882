import { useQuery, UseQueryOptions } from 'react-query';
import { getCollectibleStats, GetCollectibleStatsParams } from '../../services/sciApi/stats/index';
import { ApiError } from '../../utils/api';
import useAuth from '../auth/useAuth';
import { CollectibleHistoricalStats } from '../../sci-ui-components/types/collectibleHistoricalStats';

export const keyPrefix = 'collectible-stats';
type Key = [typeof keyPrefix, GetCollectibleStatsParams];

export function useCollectibleStats(
  params: GetCollectibleStatsParams,
  options: UseQueryOptions<CollectibleHistoricalStats, ApiError, CollectibleHistoricalStats, Key> = {}
) {
  const { isLoggedIn } = useAuth();
  const queryResult = useQuery([keyPrefix, params], async ({ signal }) => getCollectibleStats(params, signal), {
    staleTime: 1000 * 60 * 60 * 6, // 6 hours
    ...options,
    enabled: (!!isLoggedIn || params.isPublic) && !!params.collectibleId && (options?.enabled ?? true),
  });

  return queryResult;
}
