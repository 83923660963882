import { motion, LayoutGroup } from 'framer-motion';
import { collectibleNames } from '../collectibles/constants';
import ConfigurableWidgetsList, {
  WidgetConfigs,
  WidgetConfig,
  WidgetInstance,
} from '../../sci-ui-components/widgets/ConfigurableWidgetsList/ConfigurableWidgetsList';
import { collectibleTypes, isSportsCardCollectibleType } from '../../sci-ui-components/types/collectibleType';
import { getDashboardConfiguration, saveDashboardConfiguration } from '../../services/userSettings';
import CollectionOverview from '../collection/CollectionOverview/CollectionOverview';
import FavoritesWidget from '../favorites/FavoritesWidget/FavoritesWidget';
import FavoritePlayersListWidget from '../favorites/FavoritePlayersListWidget/FavoritePlayersListWidget';
import AnnouncementsWidget from '../announcements/AnnouncementsWidget/AnnouncementsWidget';
import SavedSearchesListWidget from '../search/saved-searches/SavedSearchesListWidget/SavedSearchesListWidget';
import TopMoversWidget from '../stats/TopMoversWidget/TopMoversWidget';
import VideosWidget from '../videos/VideosWidget/VideosWidget';
import useAnalytics from '../analytics/useAnalytics';
import classes from './Dashboard.module.scss';
import useShowUserPreferencesForNonUsUsers from './useShowUserPreferencesForNonUsUsers';

const widgetsRegistry: WidgetConfigs = collectibleTypes.map((collectibleType) => {
  const widgets: WidgetConfig[] = [
    {
      id: 'favorites',
      displayName: `${collectibleNames[collectibleType].shortSingular} Favorites`,
      render: () => <FavoritesWidget collectibleType={collectibleType} />,
    },
    {
      id: 'saved-searches',
      displayName: `${collectibleNames[collectibleType].shortSingular} Saved Searches`,
      render: () => <SavedSearchesListWidget collectibleType={collectibleType} />,
      sizes: {
        xl: 6,
        lg: 6,
        md: 12,
        sm: 12,
        xs: 12,
      },
    },
  ];
  if (isSportsCardCollectibleType(collectibleType)) {
    widgets.push({
      id: 'favorite-players',
      displayName: 'Player Favorites',
      render: () => <FavoritePlayersListWidget />,
      sizes: {
        xl: 6,
        lg: 6,
        md: 12,
        sm: 12,
        xs: 12,
      },
    });
  }
  widgets.push({
    id: 'top-movers',
    displayName: `${collectibleNames[collectibleType].shortSingular} Movers`,
    render: () => <TopMoversWidget collectibleType={collectibleType} />,
    sizes: {
      xl: 6,
      lg: 6,
      md: 12,
      sm: 12,
      xs: 12,
    },
  });
  return {
    id: collectibleType,
    displayName: collectibleNames[collectibleType].singular,
    widgets,
  };
});
widgetsRegistry.push({
  id: 'common',
  displayName: 'Common',
  widgets: [
    {
      id: 'collection-overview',
      displayName: 'Collection Overview',
      render: () => <CollectionOverview noBackground />,
    },
    {
      id: 'announcements',
      displayName: 'Announcements',
      render: () => <AnnouncementsWidget />,
      sizes: {
        xl: 6,
        lg: 6,
        md: 12,
        sm: 12,
        xs: 12,
      },
    },
    {
      id: 'videos',
      displayName: 'Videos',
      render: () => <VideosWidget />,
      sizes: {
        xl: 6,
        lg: 6,
        md: 12,
        sm: 12,
        xs: 12,
      },
    },
  ],
});

const defaultSetup: WidgetInstance[] = [
  { id: 'common.collection-overview' },
  { id: 'sports-card.favorites' },
  { id: 'sealed-wax-card.favorites' },
  { id: 'common.announcements' },
  { id: 'sports-card.favorite-players' },
  { id: 'sports-card.saved-searches' },
  { id: 'sealed-wax-card.saved-searches' },
  { id: 'sports-card.top-movers' },
];

export default function Dashboard({ className }: { className?: string }) {
  const { trackEvent } = useAnalytics();
  useShowUserPreferencesForNonUsUsers();

  return (
    <div className={className}>
      <LayoutGroup>
        <motion.div layout>
          <ConfigurableWidgetsList
            className={classes.widgetsList}
            widgets={widgetsRegistry}
            defaultSetup={defaultSetup}
            persistSetup={saveDashboardConfiguration}
            getPersistedSetup={getDashboardConfiguration}
            customizeText="Customize Dashboard"
            listId="dashboard"
            onCustomizationStarted={() =>
              trackEvent({
                eventName: 'DASHBOARD_CUSTOMIZATION_STARTED',
              })
            }
            onCustomizationSaved={() =>
              trackEvent({
                eventName: 'DASHBOARD_CUSTOMIZATION_SAVED',
              })
            }
            gap="regular"
            horizontalPaddings
          />
        </motion.div>
      </LayoutGroup>
    </div>
  );
}
