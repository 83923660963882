import { useMemo } from 'react';
import {
  CollectionItemsGroup,
  filterCollectionItems,
  getCollectionCollectible,
  groupCollectionItemsByCollectible,
} from '@sportscardinvestor/collection-helpers';
import { isCustomCollectible, isNonCustomCollectible } from '@sportscardinvestor/collectible-helpers';
import CollectibleMoversCardTile from '../../../../sci-ui-components/collectibles/CollectibleMoversCardTile/CollectibleMoversCardTile';
import VerticalTilesList from '../../../../sci-ui-components/VerticalTilesList/VerticalTilesList';
import { useCommonCollectibleActions } from '../../../collectibles/useCommonCollectiblesActions';
import type { MoversSortOrder, MoversSortUnit } from '../types';
import { useListAllCollectionItems } from '../../useListCollectionItems';
import { CollectibleOption } from 'sci-ui-components/collectibles/constants';
import { temp_convertCustomCollectibleToOldCustomCollectible } from '@/features/collectibles/helpers/temp_convertCustomCollectibleToOldCustomCollectible';
import { CollectibleType } from '@/sci-ui-components/types/collectibleType';
import { temp_convertCollectibleToOldCollectible } from '@/features/collectibles/helpers/temp_convertCollectibleToOldCollectible';

function getItemValue(item: CollectionItemsGroup, unit: MoversSortUnit) {
  const collectible = getCollectionCollectible(item);
  // the double negative is necessary to make the discriminated union typecheck.
  if (!isNonCustomCollectible(collectible)) {
    return 0;
  } else if (unit === 'percent') {
    return collectible.stats.last7.priceChangePercentage ?? 0;
  } else if (unit === 'dollars') {
    return collectible.stats.last7.priceChangeAmount ?? 0;
  }
  return 0;
}

export default function CollectionMoversList({
  className,
  order,
  unit,
  maxItems = 5,
  collectibleType = 'all',
}: {
  className?: string;
  order: MoversSortOrder;
  unit: MoversSortUnit;
  maxItems: number;
  collectibleType?: CollectibleOption;
}) {
  const { items } = useListAllCollectionItems({
    collectibleTypes: collectibleType === 'all' ? undefined : [collectibleType],
    sold: false,
    custom: false,
  });
  const nonSoldCollectionItemsGroups = useMemo(() => {
    const filtered = filterCollectionItems({ collectionItems: items, nonSoldOnly: true, nonManualOnly: true });
    return groupCollectionItemsByCollectible(filtered);
  }, [items]);

  const topItems = useMemo(
    () =>
      [...(nonSoldCollectionItemsGroups ?? [])]
        .map((item) => {
          const value = getItemValue(item, unit);
          return {
            item,
            value,
          };
        })
        .filter(({ value }) => (order === 'asc' ? value <= 0 : value >= 0))
        .sort(({ value: v1 }, { value: v2 }) => (order === 'asc' ? v1 - v2 : v2 - v1))
        .slice(0, maxItems),
    [nonSoldCollectionItemsGroups, order, unit, maxItems]
  );

  return (
    <>
      <VerticalTilesList className={className} gap={16}>
        {topItems?.map(({ item }) => {
          const collectible = getCollectionCollectible(item);
          return (
            <CollectionMoversListItem
              key={`${collectible.id}-${collectible.collectibleType}-${item.isCustom}`}
              item={item}
              unit={unit}
            />
          );
        })}
      </VerticalTilesList>
    </>
  );
}

function CollectionMoversListItem({ item, unit }: { item: CollectionItemsGroup; unit: MoversSortUnit }) {
  const collectible = getCollectionCollectible(item);
  const actions = useCommonCollectibleActions({
    collectibleType: collectible.collectibleType as CollectibleType,
    collectibleId: Number(collectible.id),
    query: isCustomCollectible(collectible) ? collectible.customQuery ?? collectible.query : collectible.query,
    customCollectible: isCustomCollectible(collectible)
      ? temp_convertCustomCollectibleToOldCustomCollectible(collectible)
      : undefined,
  });

  return (
    <CollectibleMoversCardTile
      key={`${collectible.id}-${collectible.collectibleType}-${item.isCustom}`}
      unit={unit}
      collectible={temp_convertCollectibleToOldCollectible(collectible)}
      data-testid="collection_mover_collectible"
      actions={{
        forSaleUrl: actions.forSaleUrl,
        onForSale: actions.trackForSaleClick,
        isFavorite: actions.isFavorite,
        onAddPriceAlert: actions.addPriceAlert,
        onAddToFavorites: actions.addFavorite,
        onRemoveFromFavorites: actions.removeFavorite,
        onChart: actions.chartCollectible,
      }}
      onClick={actions.showCollectibleDetails}
      onPopulationCountClick={actions.chartPopulationCount}
    />
  );
}
