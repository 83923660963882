import { useMutation, useQueryClient } from 'react-query';
import { FavoriteCategory } from '../../sci-ui-components/types/favoriteCategory';
import {
  createCollectibleFavoritesCategory,
  CreateCollectibleFavoritesCategoryParams,
  updateFavoriteCategory,
  UpdateFavoriteCategoryParams,
  deleteFavoriteCategory,
  DeleteFavoriteCategoryParams,
} from '../../services/sciApi/favorites';
import useAnalytics from '../analytics/useAnalytics';
import { showError, showSuccess } from '../../services/toaster';
import { getKey } from './useFavoriteCollectibleCategoriesByType';
import { CollectibleType } from 'sci-ui-components/types/collectibleType';
import { waitForConfirmation } from 'sci-ui-components/ConfirmationDialog';
import { SciApiError } from '@/services/sciApi/types';

export default function useFavoriteCollectibleCategoriesMutations() {
  const queryClient = useQueryClient();
  const { trackEvent } = useAnalytics();
  const create = useMutation({
    mutationFn: (params: CreateCollectibleFavoritesCategoryParams) => createCollectibleFavoritesCategory(params),
    mutationKey: ['add-favorite-category'],
    onSuccess: (newCategory, { collectibleType }) => {
      queryClient.setQueryData(
        getKey(collectibleType),
        (categories: FavoriteCategory[] | undefined) => categories?.concat(newCategory) ?? [newCategory]
      );
      queryClient.refetchQueries(getKey(collectibleType));
      showSuccess({
        description: 'Successfully created new category',
      });
      trackEvent({
        eventName: 'FAVORITES_CATEGORY_ADDED',
        collectibleType,
      });
    },
    onError: (error: SciApiError) => {
      const errorMessage = error.details?.error;
      showError({
        description: errorMessage,
      });
    },
  });
  const update = useMutation({
    mutationFn: (params: UpdateFavoriteCategoryParams & { collectibleType: CollectibleType }) =>
      updateFavoriteCategory(params),
    mutationKey: ['update-favorite-category'],
    onSuccess: (_, params) => {
      queryClient.refetchQueries(getKey(params.collectibleType));
      showSuccess({
        description: `Successfully renamed favorites category to "${params.name}"`,
      });
      trackEvent({
        eventName: 'FAVORITES_CATEGORY_UPDATED',
        collectibleType: params.collectibleType,
      });
    },
    onError: () => {
      showError({
        description: 'Failed to rename favorites category. Please try again.',
      });
    },
  });
  const remove = useMutation({
    mutationFn: (params: DeleteFavoriteCategoryParams & { collectibleType: CollectibleType }) =>
      deleteFavoriteCategory(params),
    mutationKey: ['delete-favorite-category'],
    onSuccess: (_, params) => {
      queryClient.refetchQueries(getKey(params.collectibleType));
      showSuccess({
        description: 'Successfully deleted favorites category',
      });
      trackEvent({
        eventName: 'FAVORITES_CATEGORY_DELETED',
        collectibleType: params.collectibleType,
      });
    },
    onError: () => {
      showError({
        description: 'Failed to delete favorites category. Please try again.',
      });
    },
  });
  const removeWithConfirmation = async (
    params: DeleteFavoriteCategoryParams & { collectibleType: CollectibleType }
  ) => {
    const confirmed = await waitForConfirmation({
      title: 'Delete favorites category',
      description: 'Are you sure you want to delete this favorites category?',
    });
    if (confirmed) {
      await remove.mutateAsync(params);
    } else {
      trackEvent({
        eventName: 'FAVORITES_CATEGORY_DELETE_CANCELLED',
        collectibleType: params.collectibleType,
      });
    }
    return confirmed;
  };

  return {
    create,
    update,
    removeWithConfirmation,
  };
}
