import { makeCollectibleDescriptionsFromParts } from '@sportscardinvestor/collectible-helpers';
import { useCollectible } from '../../collectibles/useCollectible';
import { CollectibleType } from '../../../sci-ui-components/types/collectibleType';
import { FavoriteCollectible } from '../../../sci-ui-components/types/favoriteCollectible';
import FavoritesCardTile, {
  FavoritesCardTileBaseProps,
} from '../../../sci-ui-components/collectibles/FavoritesCardTile/FavoritesCardTile';
import {
  Collectible,
  isNonCustomSealedWaxCollectible,
  isNonCustomSportsCardCollectible,
  NonCustomSportsCardCollectible,
  NonCustomSealedWaxCollectible,
} from '../../../sci-ui-components/types/collectible';
import { FavoriteCategory } from '../../../sci-ui-components/types/favoriteCategory';
import { useCommonCollectibleActions, CommonCollectibleActions } from '../../collectibles/useCommonCollectiblesActions';

export interface FavoriteTileProps {
  className?: string;
  collectibleType: CollectibleType;
  favoriteCollectible: FavoriteCollectible | null;
  favoriteCategories?: FavoriteCategory[];
  onMoveToAnotherCatgory: FavoritesCardTileBaseProps['onMoveToAnotherCatgory'];
  onRemoveFromFavorites: FavoritesCardTileBaseProps['onRemoveFromFavorites'];
}

export default function FavoriteTile({
  collectibleType,
  favoriteCollectible,
  favoriteCategories = [],
  ...other
}: FavoriteTileProps) {
  const { data: collectible } = useCollectible({
    collectibleType,
    id: favoriteCollectible?.id,
  });
  const actions = useCommonCollectibleActions({
    collectibleId: favoriteCollectible?.id ?? null,
    collectibleType,
    query: collectible?.query,
  });

  if (!collectible || !favoriteCollectible) {
    return <FavoritesCardTile {...other} isLoading={true} collectibleType={collectibleType} />;
  }
  if (isNonCustomSportsCardCollectible(collectible)) {
    return (
      <NonCustomSportsCardCollectibleFavoriteCardTile
        {...other}
        collectible={collectible}
        favoriteCategories={favoriteCategories}
        favoriteCollectible={favoriteCollectible}
        actions={actions}
      />
    );
  }
  if (isNonCustomSealedWaxCollectible(collectible)) {
    return (
      <NonCustomSealedWaxCollectibleFavoriteCardTile
        {...other}
        collectible={collectible}
        favoriteCategories={favoriteCategories}
        favoriteCollectible={favoriteCollectible}
        actions={actions}
      />
    );
  }
  return null; // NOTE: not expected to happen
}

type SpecificProps<TCollectible extends Collectible> = Omit<
  FavoriteTileProps,
  'collectible' | 'favoriteCollectible' | 'collectibleType'
> & {
  collectible: TCollectible;
  favoriteCollectible: FavoriteCollectible;
  actions: CommonCollectibleActions;
};

export function NonCustomSportsCardCollectibleFavoriteCardTile({
  collectible,
  favoriteCollectible,
  favoriteCategories,
  actions,
  ...other
}: SpecificProps<NonCustomSportsCardCollectible>) {
  const { descriptionLine1, descriptionLine2, title } = makeCollectibleDescriptionsFromParts(
    {
      collectibleType: 'sports-card',
      playerName: collectible.player?.name,
      cardNumber: collectible.cardNumber,
      setName: collectible.cardSet?.name,
      setYear: collectible.cardSet?.year,
      printRun: collectible.setVariation?.printRun,
      specificQualifier: collectible.specificQualifier,
      variationName: collectible.variation?.name,
      gradeName: collectible.grade.name,
      isRookie: collectible.isRookie,
      sportName: collectible.cardSet.sport?.name,
    },
    {
      includePrintRun: true,
    }
  );
  return (
    <FavoritesCardTile
      {...other}
      isLoading={false}
      title={title}
      collectibleType={collectible.collectibleType}
      descriptionLine1={descriptionLine1}
      descriptionLine2={descriptionLine2}
      grade={collectible.grade?.name}
      imageUrl={collectible.imageUrl}
      isRookie={collectible.isRookie}
      lastSale={favoriteCollectible?.favoriteHistoricalStats?.lastSale}
      last90DaysChangePercentage={favoriteCollectible?.favoriteHistoricalStats?.last90ChangePercentage}
      last30DaysChangePercentage={favoriteCollectible?.favoriteHistoricalStats?.last30ChangePercentage}
      last7DaysChangePercentage={favoriteCollectible?.favoriteHistoricalStats?.last7ChangePercentage}
      lastAverageSalePrice={favoriteCollectible.favoriteHistoricalStats?.lastAveragePrice}
      populationCount={collectible.populationCount}
      onPopulationCountClick={actions.chartPopulationCount}
      favoriteCategories={favoriteCategories}
      favoriteCategoryId={favoriteCollectible.categoryId}
      quickActions={{
        forSaleUrl: actions.forSaleUrl,
        onForSale: actions.trackForSaleClick,
        hasAlert: actions.hasAlert,
        onAddPriceAlert: actions.addPriceAlert,
        onAddToCollection: actions.addToCollection,
        onChart: actions.chartCollectible,
      }}
      onClick={actions.showCollectibleDetails}
    />
  );
}

export function NonCustomSealedWaxCollectibleFavoriteCardTile({
  collectible,
  favoriteCategories,
  favoriteCollectible,
  actions,
  ...other
}: SpecificProps<NonCustomSealedWaxCollectible>) {
  const { descriptionLine1, descriptionLine2, title } = makeCollectibleDescriptionsFromParts({
    collectibleType: 'sealed-wax-card',
    setName: collectible.cardSet?.name,
    boxType: collectible.boxType?.name,
    setYear: collectible.cardSet?.year,
    sportName: collectible.cardSet?.sport?.name,
  });
  return (
    <FavoritesCardTile
      {...other}
      isLoading={false}
      title={title}
      collectibleType={collectible.collectibleType}
      descriptionLine1={descriptionLine1}
      descriptionLine2={descriptionLine2}
      imageUrl={collectible.imageUrl}
      lastSale={favoriteCollectible.favoriteHistoricalStats?.lastSale}
      last90DaysChangePercentage={favoriteCollectible.favoriteHistoricalStats?.last90ChangePercentage}
      last30DaysChangePercentage={favoriteCollectible.favoriteHistoricalStats?.last30ChangePercentage}
      last7DaysChangePercentage={favoriteCollectible.favoriteHistoricalStats?.last7ChangePercentage}
      lastAverageSalePrice={favoriteCollectible.favoriteHistoricalStats?.lastAveragePrice}
      favoriteCategories={favoriteCategories}
      favoriteCategoryId={favoriteCollectible.categoryId}
      quickActions={{
        forSaleUrl: actions.forSaleUrl,
        onForSale: actions.trackForSaleClick,
        hasAlert: actions.hasAlert,
        onAddPriceAlert: actions.addPriceAlert,
        onAddToCollection: actions.addToCollection,
        onChart: actions.chartCollectible,
      }}
      onClick={actions.showCollectibleDetails}
    />
  );
}
