import { memo, useEffect, useState } from 'react';
import { collectibleNames } from '../../collectibles/constants';
import { CollectibleType } from '../../../sci-ui-components/types/collectibleType';
import WidgetHeader from '../../../sci-ui-components/widgets/WidgetHeader/WidgetHeader';
import SimpleSlider from '../../../sci-ui-components/SimpleSlider/SimpleSlider';
import WidgetFiltersRow from '../../../sci-ui-components/widgets/WidgetFiltersRow/WidgetFiltersRow';
import TextSearchField from '../../../sci-ui-components/forms/TextSearchField/TextSearchField';
import useDebouncedValue from '../../../sci-ui-components/hooks/useDebouncedValue';
import useAnalytics from '../../analytics/useAnalytics';
import { useFavoriteCollectiblesByType } from '../useFavoriteCollectibles';
import useFavoriteCollectibleCategoriesByType from '../useFavoriteCollectibleCategoriesByType';
import useFavoriteCollectibleMutations from '../useFavoriteCollectibleMutations';
import FavoriteCategoryFilter from '../FavoriteCategoryFilter';
import { editFavorite } from '../AddOrEditFavoriteDialog';
import { SportsFilter } from '../../collection/SportsFilter/SportsFilter';
import { Sport } from '../../../sci-ui-components/types/sport';
import FavoriteTile from './FavoriteTile';
import classes from './FavoritesWidget.module.scss';

const emptyItems = Array.from({ length: 10 }).map(() => null);
const numberOfItemsLeftToStartFetchingNextPage = 15;

export default memo(function FavoritesWidget({
  className,
  collectibleType,
}: {
  className?: string;
  collectibleType: CollectibleType;
}) {
  const { data: favoriteCategories } = useFavoriteCollectibleCategoriesByType({
    collectibleType,
  });
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(null);
  const [searchText, setSearchText] = useState<string | null>(null);
  const [sportFilter, setSportFilter] = useState<Sport | null>(null);
  const [debouncedSearchText] = useDebouncedValue(searchText);
  const { allItems, hasNextPage, fetchNextPage, isLoading, isFetchingNextPage } = useFavoriteCollectiblesByType({
    collectibleType,
    categoryIds: selectedCategoryId ? [selectedCategoryId] : undefined,
    searchText: debouncedSearchText,
    sportId: sportFilter?.id || null,
    limit: 10,
  });
  const handleApprochingEnd = () => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
      trackEvent({
        eventName: 'FAVORITES_SLIDER_NEXT_PAGE_REQUESTED',
        collectibleType,
      });
    }
  };
  const { moveToCategory, removeFavoriteCollectibleWithConfirmation } = useFavoriteCollectibleMutations();
  const { trackEvent } = useAnalytics();
  useEffect(() => {
    if (debouncedSearchText) {
      trackEvent({
        eventName: 'FAVORITES_SEARCH_APPLIED',
        searchText: debouncedSearchText,
        collectibleType,
      });
    }
  }, [trackEvent, debouncedSearchText, collectibleType]);

  const handleSetSportFilter = (selectedSport: Sport | null | string) => {
    if (typeof selectedSport !== 'string') {
      setSportFilter(selectedSport);
    }
  };

  const items = isLoading ? emptyItems : allItems.length ? allItems : [null];

  return (
    <div className={className}>
      <WidgetHeader className={classes.header}>{collectibleNames[collectibleType].singular} Favorites</WidgetHeader>
      <WidgetFiltersRow>
        <FavoriteCategoryFilter
          collectibleType={collectibleType}
          selectedCategoryId={selectedCategoryId}
          onChange={(categoryId) => {
            setSelectedCategoryId(categoryId);
            trackEvent({
              eventName: 'FAVORITES_CATEGORY_SELECTED',
              collectibleType,
            });
          }}
          noSelectOnAdd
          id={`favs-${collectibleType}-select`}
        />
        <SportsFilter
          selectedSport={sportFilter?.name || null}
          onChange={handleSetSportFilter}
          returnAll
          label={'Sport'}
          labelPosition={'top'}
        />
        <TextSearchField value={searchText} onChange={setSearchText} placeholder="Search" className={classes.search} />
      </WidgetFiltersRow>
      <SimpleSlider
        className={classes.slider}
        endApproachThreshold={numberOfItemsLeftToStartFetchingNextPage}
        onApproachingEnd={handleApprochingEnd}
        listClassName={classes.list}
        isEmpty={!isLoading && !allItems.length}
        emptyMessage="There are no results based upon your criteria. Please try again"
      >
        {items.map((item, index) => (
          <FavoriteTile
            className={classes.item}
            key={index}
            favoriteCollectible={item}
            collectibleType={collectibleType}
            favoriteCategories={favoriteCategories}
            onMoveToAnotherCatgory={(categoryId) => {
              if (categoryId) {
                moveToCategory.mutate({
                  categoryId,
                  favoriteId: item?.favoriteId!,
                  collectibleType,
                });
              } else {
                editFavorite({
                  collectibleId: item?.id!,
                  collectibleType,
                  favoriteId: item?.favoriteId,
                });
              }
            }}
            onRemoveFromFavorites={() =>
              removeFavoriteCollectibleWithConfirmation({
                id: item!.favoriteId,
                collectibleType,
              })
            }
          />
        ))}
      </SimpleSlider>
    </div>
  );
});
