import useAnalytics from '../analytics/useAnalytics';
import useLocalStorageValue from '../../hooks/useLocalStorageValue';
import { defaultChannel } from './constants';

const storageKey = 'sci_vch_v';

export default function useSelectedChannel(): [string, (value: string) => void] {
  const { trackEvent } = useAnalytics();
  const [valueInternal, setValueInternal] = useLocalStorageValue<string | null>(storageKey);
  const value = valueInternal ?? defaultChannel;
  const setValue = (value: string) => {
    setValueInternal(value);
    trackEvent({
      eventName: 'VIDEOS_CHANNEL_SELECTED',
      channelId: value,
    });
  };

  return [value, setValue];
}
