import { memo, useEffect, useState } from 'react';
import WidgetHeader from '../../../sci-ui-components/widgets/WidgetHeader/WidgetHeader';
import WidgetFiltersRow from '../../../sci-ui-components/widgets/WidgetFiltersRow/WidgetFiltersRow';
import TextSearchField from '../../../sci-ui-components/forms/TextSearchField/TextSearchField';
import useDebouncedValue from '../../../sci-ui-components/hooks/useDebouncedValue';
import Pagination from '../../../sci-ui-components/Pagination/Pagination';
import { CollectibleType } from '../../../sci-ui-components/types/collectibleType';
import { collectibleNames } from '../../../sci-ui-components/collectibles/constants';
import {
  GetStatsMovementsSortByValue,
  StatsPeriod,
  StatsMovementsFilters,
  StatsMovementsCollectible,
} from '../../../sci-ui-components/types/collectibleStats';
import MoversCardTile from '../../../sci-ui-components/collectibles/MoversCardTile/MoversCardTile';
import {
  getPopulationCount,
  getSportsCardGrade,
  isRookieSportsCard,
  makeCollectibleDescription,
} from '../../../sci-ui-components/utils/collectibleDescription';
import LoadingSpinner from '../../../sci-ui-components/LoadingSpinner/LoadingSpinner';
import usePaginationState, { PaginationStateConfig } from '../../../hooks/usePaginationState';
import { useCommonCollectibleActions } from '../../collectibles/useCommonCollectiblesActions';
import { useCollectible } from '../../collectibles/useCollectible';
import useSortState from '../../../hooks/useSortState';
import useAnalytics from '../../analytics/useAnalytics';
import { useStatsMovementsPaginated } from '../useStatsMovements';
import { useCollectibleStats } from '../useCollectibleStats';
import TopMoversSortSelect from './TopMoversSortSelect';

import classes from './TopMoversWidget.module.scss';

const paginationStateConfig: PaginationStateConfig = {
  defaultLimit: 10,
  limitOptions: [10],
};
const period: StatsPeriod = 'last7';
const filters: StatsMovementsFilters = {
  minSaleCount: 1,
};
const displaySearch = false;

export default memo(function TopMoversWidget({
  className,
  collectibleType,
}: {
  className?: string;
  collectibleType: CollectibleType;
}) {
  const { page, limit, offset, setPage } = usePaginationState(paginationStateConfig);
  const [searchText, setSearchText] = useState<string | null>(null);
  const [debouncedSearchText] = useDebouncedValue(searchText);
  const { setSort, sortBy, sortDirection } = useSortState<GetStatsMovementsSortByValue>({
    defaultSortBy: 'percent_change',
    defaultSortDirection: 'desc',
  });
  const { data: statMovements, isLoading } = useStatsMovementsPaginated(
    {
      collectibleType,
      limit,
      offset,
      sortBy: sortBy!,
      sortDirection,
      period,
      filters,
      searchText,
    },
    {
      keepPreviousData: true,
    }
  );

  const { trackEvent } = useAnalytics();
  useEffect(() => {
    if (debouncedSearchText) {
      trackEvent({
        eventName: 'STATS_WIDGET_SEARCH_APPLIED',
        searchText: debouncedSearchText,
        collectibleType,
      });
    }
  }, [trackEvent, debouncedSearchText, collectibleType]);

  return (
    <div className={className}>
      <WidgetHeader className={classes.header}>{collectibleNames[collectibleType].singular} Movers</WidgetHeader>
      <WidgetFiltersRow dense>
        <TopMoversSortSelect
          onChange={setSort}
          sortBy={sortBy}
          sortDirection={sortDirection}
          collectibleType={collectibleType}
        />
        {!!displaySearch && (
          <TextSearchField
            value={searchText}
            onChange={setSearchText}
            placeholder="Search"
            className={classes.search}
          />
        )}
      </WidgetFiltersRow>
      <div className={classes.list}>
        {statMovements?.items?.map((item) => {
          const collectibleStatsItem = item as StatsMovementsCollectible;
          return (
            <TopMoversItem
              className={classes.item}
              key={collectibleStatsItem.collectibleId}
              collectibleId={collectibleStatsItem.collectibleId}
              collectibleType={collectibleType}
            />
          );
        })}
        {!!isLoading && <LoadingSpinner className={classes.loading} />}
      </div>

      <Pagination
        limit={limit}
        onPageChange={setPage}
        page={page}
        noCurrentPageInfo
        totalItems={statMovements?.totalCount}
      />
    </div>
  );
});

function TopMoversItem({
  className,
  collectibleType,
  collectibleId,
}: {
  className?: string;
  collectibleType: CollectibleType;
  collectibleId: number;
}) {
  const { data: collectible = null } = useCollectible({
    collectibleType,
    id: collectibleId,
  });
  const { data: collectibleStats } = useCollectibleStats({
    collectibleType,
    collectibleId,
  });
  const actions = useCommonCollectibleActions({
    collectibleId,
    collectibleType,
    query: collectible?.query,
  });
  const { title, descriptionLine1, descriptionLine2 } = makeCollectibleDescription(collectible);
  return (
    <MoversCardTile
      className={className}
      collectibleType={collectibleType}
      title={title}
      descriptionLine1={descriptionLine1}
      descriptionLine2={descriptionLine2}
      imageUrl={collectible?.imageUrl}
      grade={getSportsCardGrade(collectible)}
      isManual={false}
      isRookie={isRookieSportsCard(collectible)}
      populationCount={getPopulationCount(collectible)}
      lastSale={collectibleStats?.lastSale}
      last30DaysChange={collectibleStats?.last30.changePercentage}
      last7DaysChange={collectibleStats?.last7.changePercentage}
      last90DaysChange={collectibleStats?.last90.changePercentage}
      lastAverageSalePrice={collectibleStats?.all.endAvg}
      actions={{
        forSaleUrl: actions.forSaleUrl,
        isFavorite: actions.isFavorite,
        onAddPriceAlert: actions.addPriceAlert,
        onAddToCollection: actions.addToCollection,
        onAddToFavorites: actions.addFavorite,
        onChart: actions.chartCollectible,
        onForSale: actions.trackForSaleClick,
        onRemoveFromFavorites: actions.removeFavorite,
      }}
      onClick={actions.showCollectibleDetails}
      onPopulationCountClick={actions.chartPopulationCount}
    />
  );
}
