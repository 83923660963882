import clsx from 'clsx';
import ToggleButton from '../../../../sci-ui-components/buttons/ToggleButton/ToggleButton';
import { collectibleNames, collectibleColors, CollectibleOption } from '../../../collectibles/constants';
import useUser from '../../../user/useUser';
import classes from './CollectibleTypeToggles.module.scss';
import useAnalytics from 'features/analytics/useAnalytics';
import { shouldReturnCollectibleType } from 'features/collection/helpers/shouldReturnCollectibleType';

const orderedCollectibleTypes: CollectibleOption[] = ['all', 'sealed-wax-card', 'sports-card'];

export default function CollectibleTypeToggles({
  className,
  selectedCollectibleTypes,
  onSelectedCollectibleTypesChange,
}: {
  className?: string;
  selectedCollectibleTypes: CollectibleOption[];
  onSelectedCollectibleTypesChange: (newCollectibleTypes: CollectibleOption[]) => void;
}) {
  const { data: user } = useUser();
  const { trackEvent } = useAnalytics();

  const allowedCollectibleTypes = orderedCollectibleTypes.filter((collectibleType) => {
    if (user && shouldReturnCollectibleType(collectibleType, user)) {
      return collectibleType;
    }
  });

  return (
    <div className={clsx(classes.root, className)} data-testid="collection_overview_toggles">
      {allowedCollectibleTypes.map((collectibleType) => {
        const isSelected = selectedCollectibleTypes.some((c) => c === collectibleType);
        const indicatorColor = collectibleColors[collectibleType];
        return (
          <ToggleButton
            key={collectibleType}
            indicatorColor={indicatorColor}
            selected={isSelected}
            onClick={() => {
              const newValue = isSelected
                ? selectedCollectibleTypes.filter((c) => c !== collectibleType)
                : [...selectedCollectibleTypes, collectibleType];
              onSelectedCollectibleTypesChange(newValue.length ? newValue : [collectibleType]);
              if (!isSelected) {
                trackEvent({
                  eventName: 'COLLECTION_OVERVIEW_COLLECTIBLE_TYPE_TOGGLED',
                  collectibleType,
                });
              }
            }}
          >
            {collectibleNames[collectibleType].shortPlural}
          </ToggleButton>
        );
      })}
    </div>
  );
}
